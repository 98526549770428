
import React, { useState } from 'react';

export const AlertContext = React.createContext({
  addError: (text) => null,
  alertText: null,
  clearAlert: () => null,
  severity: null
});

export default function AlertContextProvider({ children }) {

  const [alertText, setAlertText] = useState(null);
  const [severity, setSeverity] = useState(null);

  const contextValue = {
    addError: (text) => { 
      setSeverity('error');
      setAlertText(text);
     },
    clearAlert: () => { setAlertText(null); },
    alertText,
    severity
  };

  return (
    <AlertContext.Provider value={contextValue}>
      {children}
    </AlertContext.Provider>
  );
}
