import React, { useContext, useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate, useNavigate } from "react-router-dom";
import Login from "./pages/Login";
import Home from "./pages/Home";
import DownloadQRCode from "./pages/DownloadQRCode";
import VenueDetails from "./pages/VenueDetails";
import AuthProvider, { AuthContext } from "./contexts/Auth";
import AlertProvider from "./contexts/Alert";
import RequestProvider from "./contexts/Request";
import Alert from "./components/Alert";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import logo from "./assets/v_logo_gradient.png";

const App = () => {
  console.log('process.env', process.env);
  return (
    <AlertProvider>
      <AuthProvider>
        <RequestProvider>
          <Box sx={{ display: 'flex' }}>
            <Alert />
            <Routing />
          </Box>
        </RequestProvider>
      </AuthProvider>
    </AlertProvider>
  );
};

function Routing() {
  const { init } = useContext(AuthContext);

  useEffect(() => {
    init();
  }, []);

  return (
    <BrowserRouter>
      <LeftNavBar />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route
          path="/"
          element={
            <PrivateRoute>
              <Home />
            </PrivateRoute>
          }
        />
        <Route
          path="/download-qr-code"
          element={
            <PrivateRoute>
              <DownloadQRCode />
            </PrivateRoute>
          }
        />
         <Route
          path="/venue-details/:venueId"
          element={
            <PrivateRoute>
              <VenueDetails />
            </PrivateRoute>
          }
        />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
}

const LeftNavBar = () => {
  const { isLoggedIn } = useContext(AuthContext);
  let navigate = useNavigate();
  if (isLoggedIn()) {
    return (
      <Drawer
        sx={{
          width: 400,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: 400,
            boxSizing: "border-box",
          },
        }}
        PaperProps={{
          sx: {
            backgroundColor: "#f6f5f8",
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <img
          src={logo}
          alt="logo"
          style={{
            width: 150,
            alignSelf: "center",
            margin: 20,
          }}
          onClick={() => navigate('/')}
        />
        <LogoutButton />
      </Drawer>
    );
  }
  else {
    return <div/>;
  }
};

function PrivateRoute({ children }) {
  const { isLoggedIn } = useContext(AuthContext);
  return isLoggedIn() ? children : <Navigate to="/login" />;
}

function LogoutButton() {
  const { logout, token } = useContext(AuthContext);
  return (
    <Button
      onClick={() => {
        logout();
      }}
    >
      Logout
    </Button>
  );
}

export default App;
