import React, { useState } from 'react';

export const AuthContext = React.createContext({
  saveToken: (token) => null,
  isLoggedIn: () => null,
  init: () => null,
  getToken: () => null,
  logout: () => null
});

export default function AuthContextProvider({ children }) {

  const [token, setToken] = useState(null);

  const contextValue = {
    saveToken: (token) => { 
      localStorage.setItem('token', token);
      setToken(token);
     },
    isLoggedIn: () => { 
      return token != null || localStorage.getItem('token') != null
     },
    init: () => { setToken(localStorage.getItem('token')); },
    logout: () => {
      setToken(null);
      localStorage.removeItem('token');
    },
    getToken: () => {
      return token || localStorage.getItem('token');
    }
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
}