import React, { useContext } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { AlertContext } from '../contexts/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function CustomAlert() {
  const { alertText, clearAlert, severity } = useContext(AlertContext);
  const handleClose = () => { clearAlert(); }
  
  return (
    <Snackbar open={alertText != null} autoHideDuration={3000} onClose={handleClose}>
      <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
        {alertText}
      </Alert>
    </Snackbar>
  )
}