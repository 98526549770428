
import React, { useState, useContext } from 'react';
import { AuthContext } from './Auth';

export const RequestContext = React.createContext({
  login: (data) => null,
  filterVenues: (query) => null,
  getVenue: (query) => null,
  updateImage: (data) => null,
  deleteImage: (data) => null,
  getHost: (query) => null
});

export default function RequestContextProvider({ children }) {
  const { getToken } = useContext(AuthContext);
  const Request = (method, path, data) => {
    return new Promise((resolve, reject) => {
      const token = getToken();
      let url = `${process.env.REACT_APP_API_URL}${path}`;
      if (method === "GET" && data) {
        url = `${url}?${new URLSearchParams(data)}`
      }
      let requestOptions = {
        method: method,
        headers: { 
          'Content-Type': 'application/json',
          'venuetoken': token
        }
      };

      if (method != "GET" && data) {
        requestOptions.body = JSON.stringify(data);
      }
  
      fetch(url, requestOptions)
      .then(response => response.json())
      .then(data => {
        if (data.http_status && data.http_status != 200) {
          if (data.display_message) {
            reject(data.display_message);
          }
          else {
            reject("Unknown Error");
          }
        }
        else {
          resolve(data);
        }
      })
      .catch(err => {
        console.error(err);
        reject(err);
      });
    });
  }

  const contextValue = {
    login: (data) => { 
      return Request('POST', '/v1/admin/sign_in/1.0.0', data);
     },
     filterVenues: (query) => {
      return Request('GET', '/v1/venue/filter/1.0.0', query);
     },
     getVenue: (query) => {
      return Request('GET', '/v1/venue', query);
     },
     updateImage: (data) => {
      return Request('PATCH', '/v1/venue/image/1.0.0', data);
     },
     deleteImage: (data) => {
      return Request('DELETE', '/v1/venue/image/1.0.0', data);
     },
     getHost: (query) => {
      return Request('GET', '/v1/hostuser', query);
     }
  };

  return (
    <RequestContext.Provider value={contextValue}>
      {children}
    </RequestContext.Provider>
  );
}