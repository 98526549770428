import React, { useContext, useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { RequestContext } from '../contexts/Request';
import { AlertContext } from '../contexts/Alert';
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";

const Home = () => {
  const { addError } = useContext(AlertContext);
  const { filterVenues } = useContext(RequestContext);
  const [venues, setVenues] = useState([]);
  const [sortedVenues, setSortedVenues] = useState([]);
  const [sort, setSort] = useState({
    direction: 'asc',
    by: 'name'
  });

  let navigate = useNavigate();
  useEffect(async () => {
    try {
      const res = await filterVenues({market_id: 1});
      setVenues(res);
    } catch (error) {
      addError(error);
    }
  }, []);

  useEffect(() => {
    const sortedVenues = venues.sort((a, b) => {
      if (sort.by == 'created_at') {
        a.created_at = new Date(a.created_at);
        b.created_at = new Date(b.created_at);
      }
      if (a[sort.by] > b[sort.by]) {
        return sort.direction == 'asc' ? 1 : -1
      }
      else if (a[sort.by] < b[sort.by]) {
        return sort.direction == 'asc' ? -1 : 1
      }
      else {
        return 0;
      }
    });
    setSortedVenues([...sortedVenues]);
  }, [venues, sort]);

  return (
    <Grid container spacing={2} style={{ margin: 40 }}>
      <Grid item xs={9}>
        <Typography variant="h2" gutterBottom color={"#41437d"}>
          My Venues
        </Typography>
      </Grid>
      <Grid container xs={3} justifyItems="flex-end" alignSelf={"center"}>
        <Button 
          variant="contained"
          onClick={() => navigate(`/download-qr-code`)}>
          Download QR Code
        </Button>
      </Grid>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={sort.by == 'name'}
                  direction={sort.direction}
                  onClick={() => setSort({
                    by: 'name',
                    direction: sort.direction == 'asc' ? 'desc' : 'asc'
                  })}>
                  Venue Name
                </TableSortLabel>
              </TableCell>
              <TableCell>Address</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Images</TableCell>
              <TableCell>
               <TableSortLabel
                  active={sort.by == 'created_at'}
                  direction={sort.direction}
                  onClick={() => setSort({
                    by: 'created_at',
                    direction: sort.direction == 'asc' ? 'desc' : 'asc'
                  })}>
                  Uploaded
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedVenues.map((venue) => {
              var images = [];
              if (venue.venueImageUrl) {
                images.push('Background');
              }
              if (venue.venueLogoUrl) {
                images.push('Logo');
              }
              return (
                <TableRow
                  key={venue.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell 
                    component="th" 
                    scope="row" 
                    style={{
                      color: '#3f5b83',
                      cursor: "pointer"
                    }}
                    onClick={() => {
                      navigate(`/venue-details/${venue.id}`);
                    }}>
                    {venue.name}
                  </TableCell>
                  <TableCell>
                    {venue.address}
                  </TableCell>
                  <TableCell>
                    {venue.phone_numbers ? venue.phone_numbers.join(', ') : ''}
                  </TableCell>
                  <TableCell>
                    {images.join(' / ')}
                  </TableCell>
                  <TableCell>
                    {venue.created_at ? new Date(venue.created_at).toLocaleDateString() : ''}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};

export default Home;
