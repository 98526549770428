import React, { useContext, useState, useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { RequestContext } from "../contexts/Request";
import { AlertContext } from "../contexts/Alert";
import { useNavigate, useParams } from "react-router-dom";
import ImageUploading from "react-images-uploading";

const VenueDetails = () => {
  const { addError } = useContext(AlertContext);
  const { getVenue, updateImage, deleteImage } = useContext(RequestContext);
  const [venue, setVenue] = useState({});
  const [venueLogo, setVenueLogo] = useState();
  const [backgroundLogo, setBackgroundLogo] = useState();
  let params = useParams();

  useEffect(async () => {
    try {
      const res = await getVenue({ id: params.venueId });
      if (res.venueImageUrl) {
        setBackgroundLogo({url: res.venueImageUrl});
      }
      if (res.venueLogoUrl) {
        setVenueLogo({url: res.venueLogoUrl});
      }
      console.log('res', res);
      setVenue(res);
    } catch (error) {
      addError(error);
    }
  }, []);

  const SaveImage = async (imgType, imageList) => {
    if (imageList.length > 0) {
      const image = imageList[0];
      try {
        var data = {
          venue_id: venue.id, 
          img_data: image.data_url.substring(image.data_url.indexOf(",") + 1), 
          img_type: imgType
        };
        await updateImage(data);
        if (imgType === 'logo') {
          setVenueLogo(imageList[0]);
        }
        if (imgType === 'default') {
          setBackgroundLogo(imageList[0]);
        }
      } catch (error) {
        addError(error);
      }
    }
  }

  const DeleteImage = async (imgType) => {
    try {
      var data = {
        venue_id: venue.id, 
        img_type: imgType
      };
      await deleteImage(data);
      if (imgType === 'logo') {
        setVenueLogo(null);
      }
      if (imgType === 'default') {
        setBackgroundLogo(null);
      }
    } catch (error) {
      addError(error);
    }
  }

  return (
    <Grid container spacing={2} style={{ margin: 40 }}>
      <Grid item xs={12}>
        <Typography variant="h2" gutterBottom color={"#41437d"}>
          Venue: {venue.name || ""}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom color={"#41437d"}>
          Overview
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={16}>
          <Grid item xs={6}>
            <Typography variant="h4" gutterBottom color={"#41437d"}>
              Description of Venue
            </Typography>
            <TextField
              disabled={true}
              fullWidth
              multiline
              margin="normal"
              label="Name of Venue"
              variant="standard"
              value={venue.name || ""}
            />
            <TextField
              disabled={true}
              fullWidth
              multiline
              margin="normal"
              label="Address"
              variant="standard"
              value={venue.address || ""}
            />
            <TextField
              disabled={true}
              fullWidth
              multiline
              margin="normal"
              label="Neighborhood"
              variant="standard"
              value={venue.neighborhood || ""}
            />
            <TextField
              disabled={true}
              fullWidth
              multiline
              margin="normal"
              label="Description"
              variant="standard"
              value={venue.description || ""}
            />
          </Grid>
          <Grid item xs={6}>
            <Grid container spacing={6}>
              <Grid item sm={12}>
                <Typography variant="h4" gutterBottom color={"#41437d"}>
                  Venue Media
                </Typography>
              </Grid>
              <Grid item sm={6}>
                <Typography
                  variant="h6"
                  gutterBottom
                  color={"#41437d"}
                >
                  Background Logo
                </Typography>
                <ImageUploading
                  value={backgroundLogo ? [backgroundLogo] : []}
                  onChange={(imageList) => SaveImage('default', imageList)}
                  dataURLKey="data_url"
                  maxNumber={1}
                >
                  {({
                    imageList,
                    onImageUpload,
                    onImageRemove
                  }) => (
                    <div className="upload__image-wrapper">
                      {backgroundLogo == null && (
                        <Button onClick={onImageUpload}>
                          Upload Background Logo
                        </Button>
                      )}
                      {imageList.map((image, index) => (
                        <div key={index} className="image-item">
                          <img 
                            src={image.data_url || image.url} 
                            alt="" 
                            width="100%" 
                          />
                          <div>
                            <Button
                              color="error"
                              style={{ width: "100%" }}
                              onClick={() => DeleteImage('default')}
                            >
                              Remove
                            </Button>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </ImageUploading>
              </Grid>
              <Grid item sm={6}>
                <Typography
                  variant="h6"
                  gutterBottom
                  color={"#41437d"}
                >
                  Venue Logo
                </Typography>
                <ImageUploading
                  value={venueLogo ? [venueLogo] : []}
                  onChange={(imageList) => SaveImage('logo', imageList)}
                  dataURLKey="data_url"
                  maxNumber={1}
                >
                  {({
                    imageList,
                    onImageUpload,
                    onImageRemove
                  }) => (
                    <div className="upload__image-wrapper">
                      {venueLogo == null && (
                        <Button onClick={onImageUpload}>
                          Upload Venue Logo
                        </Button>
                      )}
                      {imageList.map((image, index) => (
                        <div key={index} className="image-item">
                          <img 
                            src={image.data_url|| image.url}
                            width="100%" 
                          />
                          <div>
                            <Button
                              color="error"
                              style={{ width: "100%" }}
                              onClick={() => DeleteImage('logo')}
                            >
                              Remove
                            </Button>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </ImageUploading>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default VenueDetails;
