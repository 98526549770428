import React, { useContext, useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import { AlertContext } from "../contexts/Alert";
import { RequestContext } from "../contexts/Request";
import { saveAs } from 'file-saver';

const DownloadQRCode = () => {
  const { addError } = useContext(AlertContext);
  const { getHost } = useContext(RequestContext);
  let navigate = useNavigate();
  const [link, setLink] = useState("");
  return (
    <Grid container spacing={2} style={{ margin: 40 }}>
      <Grid item xs={12}>
        <Typography variant="h2" gutterBottom color={"#41437d"}>
          Download QR Code
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField 
          value={link}
          onChange={e => {
            setLink(e.target.value)
          }}
          label="Enter Host QR Code Link" 
          variant="outlined" 
          placeholder="https://linking.venueapp-system.com/qrcode-scan?hostid=xxxxxx"
          style={{
            width: 700
          }}/>
      </Grid>
      <Grid item xs={12}>
        <Button 
          disabled={link == ""}
          variant="contained"
          onClick={async () => {
            const hostId = new URLSearchParams(link.substring(link.indexOf("?")+1)).get("hostid");
            if (hostId) {
              console.log('hostId', hostId);
              try {
                var res = await getHost({ hostId });
                console.log('res', res);
                saveAs(res.QRCodeUrl, `test.png`)
              } catch (error) {
                console.log('error', error);
                addError("Error getting host QR Code");
              }
            }
            else {
              addError("Invalid URL. No host id found");
            }
          }}>
          Download QR Code
        </Button>
      </Grid>
    </Grid>
  );
};

export default DownloadQRCode;
